<template>
    <div>
      <Header />
      <ConsultaCadastros />
    </div>
  </template>
  
  <script>
  import Header from "../../components/Header.vue";
  import ConsultaCadastros from "../../components/Cidades/ConsultaCidades.vue";
  import Footer from "../../components/Footer.vue";
  
  export default {
    components: {
      Header,
      ConsultaCadastros,
      Footer,
    },
    data() {
      return {
      };
    },
  };
  </script>
  